<template>
  <div>
    <b-card>
      <b-row>
        <b-col cols="12">
          <b-form-group
            class="font-small-3"
            label="기준 사이트"
            label-for="site-select"
            style="max-width: 500px"
          >
            <b-form-select
              id="site-select"
              v-model="siteSelected"
              :options="authSite"
              class="font-small-3"
              size="sm"
            />
          </b-form-group>
        </b-col>
      </b-row>
    </b-card>
    <b-card>
      <div>
        <h4 class="">
          <feather-icon
            icon="BoxIcon"
            style="width: 18px; height: 18px"
          />
          공지사항 관리
          <b-badge variant="primary" style="font-size: xx-small">
            완료
          </b-badge>
        </h4>
        <hr>
        <b-table
          responsive
          show-empty
          hover
          empty-text="No matching records found"
          :items="fetchBoard"
          :fields="fields1"
          style="text-align: center; font-size: small;"
          @row-clicked="rowClickHandler"
        >
          <template #cell(no)="data"
                    size="sm"
                    style="text-align: center; min-width: 3rem"
          >
            {{ data.index + 1 }}
          </template>
          <template #cell(title)="data">
            <div
              size="sm"
              style="text-align: left; min-width: 15rem"
            >
              {{ data.item.title }}
            </div>
          </template>
          <template #cell(createdAt)="data">
            <div
              size="sm"
              style="text-align: center; min-width: 7rem"
            >
              {{ data.item.createdAt | formatDate('YYYY-MM-DD HH:mm') }}
            </div>
          </template>
          <template #cell(updatedAt)="data">
            <div
              size="sm"
              style="text-align: center; min-width: 7rem"
            >
              {{ data.item.updatedAt | formatDate('YYYY-MM-DD HH:mm') }}
            </div>
          </template>
          <template
            #cell(edit)="data"
            v-slot:button-content
          >
            <div class="editClass" style="min-width: 3rem" @click.stop>
              <feather-icon
                v-b-modal.modal-lg
                icon="Edit2Icon"
                class="mr-1"
                style="color:rgb(229,166,48);"
                type="button"
                @click="rowClick(data)"
              />
              <feather-icon
                icon="TrashIcon"
                class=""
                style="color:rgb(229,166,48);"
                @click="submit('deleteBoard', data.item.title)"
              />
            </div>
          </template>
        </b-table>
        <b-modal
          id="modal-lg"
          ref="my-modal"
          small
          title="상세설정"
          centered
          button-size="sm"
          size="lg"
          style="max-width: 400px !important;"
          ok-title="저장"
          @ok="submit('upsertBoard')"
        >
          <div
            class="text-info">
            {{ detailTitle }}
          </div>
          <div class="mt-2">
            <quill-editor
              v-model="detailContent"
              class="editor"
              ref="myTextEditor"
              :disabled="false"
              :options="editorOption"
            />
          </div>
        </b-modal>
<!--        :to="{ name: 'notice-settings-detail', params: { type: 'notice', id: tableItems1.length + 1 } }"-->
        <b-button
          ref="submit"
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          type="submit"
          variant="primary"
          class="content-right w-auto mr-1 mt-1"
          size="sm"
          v-b-modal.modal-lg-new
          @click="noticeNew()"
        >
          공지사항 생성
        </b-button>
        <b-modal
          id="modal-lg-new"
          ref="my-modal"
          small
          title="공지사항 생성"
          centered
          button-size="sm"
          size="lg"
          style="max-width: 400px !important;"
          ok-title="저장"
          @ok="submit('upsertBoard')"
        >
          <b-form @submit.prevent>
            <b-row>
              <b-col cols="6">
                <b-form-group
                  class="font-small-3"
                  label="제목"
                  label-for="notice-title"
                  label-cols-sm="2"
                >
                  <b-form-input
                    id="popup-title"
                    v-model="detailTitle"
                    class="font-small-3 text-info"
                    size="sm"
                    placeholder="공지사항 제목을 입력하세요"
                  />
                </b-form-group>
              </b-col>
            </b-row>
          </b-form>
          <div class="mt-2">
            <quill-editor
              v-model="detailContent"
              :options="editorOption"
            />
          </div>
        </b-modal>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BRow, BCol, BCard, BCardBody, BCardText, BForm, BFormInput, BFormGroup, BButton, BButtonToolbar,
  BTable, BAvatar, BBadge, BFormSelect, BPagination, BInputGroup, BInputGroupAppend, BFormTextarea,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import Swal from 'sweetalert2'

import { createNamespacedHelpers } from 'vuex'

const noticeStore = createNamespacedHelpers('noticeStore')
const settingsStore = createNamespacedHelpers('settingsStore')

import { quillEditor } from 'vue-quill-editor'

import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import { FETCH_BOARD } from '@/store/notice/action'
import { DELETE_BOARD, UPSERT_BOARD } from '@/store/notice/mutation'

import NoticeSettingsDetail from '@/views/boards/NoticeSettingsDetail'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardBody,
    BCardText,
    BForm,
    BFormInput,
    BFormGroup,
    BButton,
    BButtonToolbar,
    BTable,
    BAvatar,
    BBadge,
    BFormSelect,
    BPagination,
    BInputGroup,
    BInputGroupAppend,
    BFormTextarea,
    quillEditor,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      userData: JSON.parse(localStorage.getItem('userData')),
      authSite: localStorage.getItem('authSite').split(','),
      siteSelected: localStorage.getItem('authSiteSelected'),
      userIp: localStorage.getItem('ip'),
      detailTitle: '',
      detailDataRow: 0,
      fields1: [
        {
          key: 'no', sortable: false, label: 'No.', thClass: 'col1',
        },
        {
          key: 'title', sortable: false, label: '제목', thClass: 'col2',
        },
        {
          key: 'enrollId', sortable: false, label: '등록자', thClass: 'col3',
        },
        {
          key: 'createdAt', sortable: false, label: '등록일', thClass: 'col4',
        },
        {
          key: 'updatedAt', sortable: false, label: '수정일', thClass: 'col5',
        },
        {
          key: 'edit', sortable: false, label: '상세', thClass: 'col7',
        },
      ],
      tableItems1: [
        {
          no: 1,
          title: '첫번째 공지입니다 공지사항 테스트 입력',
          enrollId: 'administrator',
          createdAt: '2022-01-01 23:10:23',
          updatedAt: '2022-01-01 23:10:23',
        },
        {
          no: 2,
          title: '두번째 공지입니다 공지사항 테스트 입력',
          enrollId: 'administrator',
          createdAt: '2022-01-01 23:10:23',
          updatedAt: '2022-01-01 23:10:23',
        },
        {
          no: 3,
          title: '세번째 공지입니다 공지사항 테스트 입력',
          enrollId: 'administrator',
          createdAt: '2022-01-01 23:10:23',
          updatedAt: '2022-01-01 23:10:23',
        },
      ],
      detailContent: '',
      detailContentNew: `<h1>공지사항 예시</h1>
                      <p><br></p>
                      <p>[드라이브]<p><br>
                      <p>– Drive탐색기 Tray 메뉴에서 노출되던 ‘정보’가 환경설정 하위로 이동합니다.<p><br>
                      <p>  [캘린더]<p><br>
                      <p>– 모바일앱 캘린더에서 일정을 작성하거나 조회 시 ‘장소’ 필드 하단에 제공했던 주소 정보 표기 영역이 제거됩니다.<p><br>
                      <p>  [주소록]<p><br>
                      <p>– PC웹에서 좌측 상단 ‘그룹’ 아이콘 선택 후 보이는 페이지에서 ‘그룹 마스터’ 필드가 제거됩니다.<p><br>
                      <p>– PC웹에서 구성원 검색 시 보이는 페이지에서 ‘그룹’ 필드가 제거됩니다.<p><br>
                      <p><br></p>
                     `,
      editorOption: {
        // debug: 'info',
        modules: {
          toolbar: [
            ['bold', 'italic', 'underline', 'strike'], // <strong>, <em>, <u>, <s>
            ['blockquote', 'code-block'], // <blockquote>, <pre class="ql-syntax" spellcheck="false">
            [{ header: 1 }, { header: 2 }], // <h1>, <h2>
            [{ list: 'ordered' }, { list: 'bullet' }],
            [{ script: 'sub' }, { script: 'super' }], // <sub>, <sup>
            [{ indent: '-1' }, { indent: '+1' }], // class제어
            [{ direction: 'rtl' }], // class 제어
            [{ size: ['small', false, 'large', 'huge'] }], // class 제어 - html로 되도록 확인
            [{ header: [1, 2, 3, 4, 5, 6, false] }], // <h1>, <h2>, <h3>, <h4>, <h5>, <h6>, normal
            [{ font: [] }], // 글꼴 class로 제어
            [{ color: [] }, { background: [] }], // style="color: rgb(230, 0, 0);", style="background-color: rgb(230, 0, 0);"
            [{ align: [] }], // class
            // ["clean"],
            ['link', 'image', 'video'],
          ],
        },
        placeholder: '글을 입력해 주세요...',
        readOnly: true,
        theme: 'snow',
      },
    }
  },
  computed: {
    ...noticeStore.mapGetters({
      fetchBoard: 'fetchBoard',
    }),
    ...settingsStore.mapGetters({
      fetchSetSiteAdmin: 'fetchSetSiteAdmin',
    }),
    noticeStore() {
      // return this.$store.state.noticeStore.fetchBoard
      return this.detailContent
    },
  },
  watch: {
    siteSelected: {
      handler(event) {
        this.fetchData()
        localStorage.setItem('authSiteSelected', event)
        this.$store.commit('footerStore/setSelectedSiteIndex', this.authSite.indexOf(event))
      },
      immediate: false,
      deep: false,
    },
  },
  mounted() {
    // this.siteAdminData = this.fetchSetSiteAdmin
    // this.authSite = this.fetchSetSiteAdmin.map(item => item.authSite)
    // this.siteSelected = this.authSite[0]
    this.fetchData()
  },
  methods: {
    // methods에는 mapAction, mapMutate를 선언한다
    ...noticeStore.mapActions({
      $fetchBoard: FETCH_BOARD,
      $upsertBoard: UPSERT_BOARD,
      $deleteBoard: DELETE_BOARD,
    }),
    async fetchData() {
      const mySite = this.siteSelected
      await this.$fetchBoard({
        site: mySite,
      })
    },
    rowClick(data) {
      // console.log('editdata: ', data)
      this.detailDataRow = data.item.no
      this.detailTitle = data.item.title
      this.detailContent = data.item.content
    },
    async submit(target, row) {
      await Swal.fire({
        title: '변경내용을 저장하시겠습니까?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes',
        background: 'rgb(3,11,37)',
        customClass: {
          cancelButton: 'btn btn-dark',
          confirmButton: 'btn btn-primary mr-1',
        },
      }).then(result => {
        if (result.isConfirmed) {
          this.confirmYes(target, row)
        }
      })
    },
    // confirm Yes
    async confirmYes(target, row) {
      const mySite = this.siteSelected
      const adminId = this.userData.userid
      try {
        if (target === 'upsertBoard') {
          await this.$upsertBoard({
            site: mySite,
            title: this.detailTitle,
            content: this.detailContent,
            enrollId: adminId,
            userIp: this.userIp,
          })
        }
        if (target === 'deleteBoard') {
          await this.$deleteBoard({
            site: mySite,
            title: row,
            enrollId: adminId,
            userIp: this.userIp,
          })
        }
      } catch {
        console.error()
      } finally {
        Swal.fire({
          title: '저장 완료',
          icon: 'success',
          timer: 5000,
          background: 'rgb(3,11,37)',
          customClass: {
            confirmButton: 'btn btn-primary',
          },
        }).then(result => {
          this.fetchData()
        })
      }
    },
    // delete row
    deleteRow(type, row) {
      Swal.fire({
        title: '선택한 항목을 삭제하시겠습니까?',
        // text: Number(this.desiredBet).toLocaleString(),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes',
        background: 'rgb(3,11,37)',
        customClass: {
          cancelButton: 'btn btn-dark',
          confirmButton: 'btn btn-primary mr-1',
        },
      }).then(result => {
        if (result.isConfirmed) {
          this.confirmYes(type, row)
        }
      })
    },
    noticeNew() {
      this.detailTitle = ''
      this.detailContent = this.detailContentNew
    },
    rowClickHandler(record, index, type) {
      // 'record' will be the row data from items
      // `index` will be the visible row number (available in the v-model 'shownItems')
      // console.log(record) // This will be the item data for the row
      // console.log(index)
      // console.log(type.srcElement.parentElement.ariaColIndex)
      // console.log(type.srcElement._prevClass)
      // if (type.target.ariaColIndex !== '6' && type.srcElement.parentElement.ariaColIndex !== '6' && type.srcElement._prevClass !== 'feather feather-trash') {
      if (type.target.ariaColIndex !== '6') {
        this.detailDataRow = record.no
        this.detailTitle = record.title
        this.detailContent = record.content
        this.$refs['my-modal'].show()
      }
    },
  },
}

</script>

<style scoped>
</style>
